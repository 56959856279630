import axios from "axios";

const customInstance = axios.create({
  // baseURL: "https://api-staging-alpha.bleaum.dev/",

  // baseURL: "https://api-alpha.bleaum.dev/",
  // baseURL: "https://graphql-cannaby-consumer.herokuapp.com/",

  baseURL: "https://api-alpha.bleaum.dev/",

  // baseURL: "https://graphql-cannaby-consumer.hyerokuapp.com/",
  // baseURL: "https://graphql-cannaby-consumer.herokuapp.com/",

  // baseURL: "http://localhost:8080/",
  // baseURL: "https://cannaby-testing.herokuapp.com/",
  // baseURL: "https://hamiltons.staging.bleaum.io/",
  headers: { Accept: "application/json" },
  withCredentials: true,
});

export default customInstance;
